<template>
    <div>
        <v-row align="center">
            <v-col cols="12" lg="3">
                <v-row>
                    <v-col cols="6">
                        <TextDatePicker
                            clearable
                            prepend-icon="mdi-calendar"
                            hide-details
                            :disabled="!items || !items.length"
                            label="Début d'indisponibilité"
                            v-model="selectedStartDate"
                            :max="selectedEndDate"
                        />
                    </v-col>
                    <v-col cols="6">
                        <TextDatePicker
                            clearable
                            prepend-icon="mdi-calendar"
                            hide-details
                            :disabled="!items || !items.length"
                            label="Fin d'indisponibilité"
                            v-model="selectedEndDate"
                            :min="selectedStartDate"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="3">
                <TextSelectPicker
                    clearable
                    hide-details
                    :disabled="!beneficiaries || !beneficiaries.length"
                    v-model="selectedBeneficiaries"
                    :items="beneficiaries"
                    label="Bénéficiaire"
                    no-data-text="Aucun résultat pour ce bénéficiaire."
                    multiple
                    :maxChipsDisplayed="1"
                    deletableChips
                />
            </v-col>
            <v-col cols="12" md="6" lg="3">
                <TextSelectPicker
                    clearable
                    hide-details
                    :disabled="!reasons || !reasons.length"
                    no-data-text="Aucun résultat pour ce motif."
                    v-model="selectedReasons"
                    :items="reasons"
                    label="Motif"
                    multiple
                    :maxChipsDisplayed="1"
                    deletableChips
                />
            </v-col>
            <v-col cols="12" md="6" lg="3">
                <TextSelectPicker
                    clearable
                    hide-details
                    :disabled="!statuses || !statuses.length"
                    no-data-text="Aucun résultat pour ce statut."
                    v-model="selectedStatuses"
                    :items="statuses"
                    label="Statut"
                    multiple
                    :maxChipsDisplayed="2"
                    deletableChips
                />
            </v-col>
        </v-row>

        <v-tooltip
            bottom
            :activator="selectedElement"
            v-show="isTooltipOpened"
            v-model="isTooltipOpened"
        >
            {{ selectedMessage }}
        </v-tooltip>

        <v-data-table
            class="elevation-1 mt-2"
            :loading="isTableLoading"
            loading-text="Chargement des données..."
            must-sort
            :headers="headers"
            :footer-props="footerProps"
            :options="options"
            :items="items"
            no-data-text="Aucune donnée à afficher."
            no-results-text="Aucune donnée correspond à votre recherche."
        >
            <template v-slot:[`item.statut`]="{ item }">
                <StatusCellSection v-if="item" :item="item" />
            </template>

            <template v-slot:[`item.date_created`]="{ item }">
                {{ item.date_created | formatDateTime }}
            </template>

            <template v-slot:[`item.nom`]="{ item }">
                {{ item.nom }} {{ item.prenom }}
                <span class="modified-by" v-if="item.user_created !== item.gid_collaborateur">
                    par
                    <strong>{{ item.nom_created }} {{ item.prenom_created }}</strong>
                </span>
            </template>

            <template v-slot:[`item.date_debut`]="{ item }">
                {{ item.date_debut | formatDateTime }}
            </template>

            <template v-slot:[`item.date_fin`]="{ item }">
                {{ item.date_fin | formatDateTime }}
            </template>

            <template v-slot:[`item.lib_absence`]="{ item }">
                {{ item.indisponibilite }} - {{ item.lib_absence }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    @click.stop="showHistoryModal(item)"
                    @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.SHOW_HISTORIC)"
                >
                    {{ ACTIONS_INDISPO_ICONS.SHOW_HISTORIC.icon }}
                </v-icon>
            </template>
        </v-data-table>

        <UnavHistoricModal
            v-if="isHistoricModalDisplayed && selectedUnavHistoric && selectedUnavHistoric.length"
            v-model="isHistoricModalDisplayed"
            :unavailabilityHistoric="selectedUnavHistoric"
        />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import unavailabilitiesApi from '@/api/unavailabilities';

import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import {
    STATUT_INDISPO_LABEL,
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_ICONS,
} from '@/constants/unavailabilities';

import TextDatePicker from '@/components/pickers/TextDatePicker.vue';
import TextSelectPicker from '@/components/pickers/TextSelectPicker.vue';
import StatusCellSection from '@/components/sections/table/StatusCellSection.vue';
import UnavHistoricModal from '@/components/UnavHistoricModal.vue';

const fileName = 'HistoryDataTable.vue';

export default {
    name: 'HistoryDataTable',

    components: {
        TextDatePicker,
        TextSelectPicker,
        StatusCellSection,
        UnavHistoricModal,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        isTableLoading: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            ACTIONS_INDISPO,
            ACTIONS_INDISPO_ICONS,

            selectedStartDate: '',
            selectedEndDate: '',
            selectedReasons: [],
            selectedStatuses: [],
            selectedBeneficiaries: [],

            selectedMessage: undefined,
            selectedElement: null,
            selectedUnavHistoric: undefined,

            isTooltipOpened: false,
            isHistoricModalDisplayed: false,

            footerProps: {
                itemsPerPageText: 'Élements par page : ',
                itemsPerPageOptions: [10, 20, 30],
                pageText: '{0}-{1} sur {2}',
            },

            options: {
                itemsPerPage: 10,
                sortBy: ['date_created'],
                sortDesc: [true],
            },

            headers: [
                {
                    text: 'Statut',
                    value: 'statut',
                    width: '85px',
                    filter: (value) => {
                        return this.selectedStatuses && this.selectedStatuses.length
                            ? this.selectedStatuses.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Date de la demande',
                    value: 'date_created',
                    width: '175px',
                },
                {
                    text: 'Bénéficiaire',
                    value: 'nom',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedBeneficiaries && this.selectedBeneficiaries.length
                            ? this.selectedBeneficiaries.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Début indisponibilité',
                    value: 'date_debut',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedStartDate
                            ? moment(value).isAfter(moment(this.selectedStartDate))
                            : true;
                    },
                },
                {
                    text: 'Fin indisponibilité',
                    value: 'date_fin',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedEndDate
                            ? moment(value).isBefore(moment(this.selectedEndDate))
                            : true;
                    },
                },
                {
                    text: 'Motif',
                    value: 'lib_absence',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedReasons && this.selectedReasons.length
                            ? this.selectedReasons.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    width: '100px',
                },
            ],
        };
    },

    computed: {
        beneficiaries() {
            return this.items
                ? this.items.map((item) => ({
                      text: `${item.nom} ${item.prenom}`,
                      value: item.nom,
                  }))
                : [];
        },
        reasons() {
            return this.items
                ? this.items.map((item) => `${item.indisponibilite} - ${item.lib_absence}`)
                : [];
        },
        statuses() {
            return this.items
                ? this.items.map((item) => ({
                      text: STATUT_INDISPO_LABEL[item.statut],
                      value: item.statut,
                  }))
                : [];
        },
    },

    filters: {
        formatDateTime(date) {
            return date ? moment(date).format(DATE_TIME_FORMAT) : '';
        },
    },

    methods: {
        ...mapMutations(['showErrorToast']),

        showActionsTooltip(nativeEvent, action) {
            this.selectedMessage = ACTIONS_INDISPO_VERB[action];
            this.selectedElement = nativeEvent.target;
        },
        async showHistoryModal(item) {
            try {
                this.showSpinner();
                this.selectedUnavHistoric = await unavailabilitiesApi.getUnavailabilityHistoric(
                    item.id
                );
                this.isHistoricModalDisplayed = true;
                this.hideSpinner();
            } catch (error) {
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
            }
        },

        showSpinner() {
            this.$emit('show-spinner');
        },
        hideSpinner() {
            this.$emit('hide-spinner');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-data-table-header {
    background-color: $very-light-gray;
}
.date-label {
    font-size: 12px;
}
.modified-by {
    color: $primary !important;
    display: block;
    font-style: italic;
    line-height: 0.6rem;
    font-size: 0.7rem;
    margin-left: -0.2rem;
}
</style>
