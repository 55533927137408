<template>
    <v-dialog @input="updateValue" :value="value" scrollable max-width="600">
        <v-card tile>
            <v-card-title>
                <span>{{ ACTIONS_INDISPO_LABEL[action] }}</span>

                <v-spacer />

                <v-btn icon @click="updateValue(false)" dark>
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <Spinner v-if="isLoaderDisplayed" />

            <div v-if="unavailability">
                <v-container fluid>
                    <UnavSummarySection :unavailability="unavailability" />

                    <v-form v-model="isValid">
                        <v-text-field
                            outlined
                            label="Commentaire (facultatif)"
                            v-model="enteredComment"
                            :counter="255"
                            :rules="commentRules"
                            required
                        />
                    </v-form>
                </v-container>

                <v-card-actions>
                    <v-row no-gutters align="center" justify="end">
                        <v-col cols="12" sm="2" class="ml-2 my-1">
                            <v-btn
                                class="secondary"
                                depressed
                                tile
                                large
                                :ripple="false"
                                @click="updateValue(false)"
                            >
                                Annuler
                            </v-btn>
                        </v-col>

                        <v-col cols="12" sm="5" class="ml-2 my-1">
                            <v-btn
                                :class="ACTIONS_INDISPO_ICONS[action].color"
                                tile
                                depressed
                                block
                                large
                                :ripple="false"
                                @click="performAction()"
                            >
                                <v-icon left>
                                    {{ ACTIONS_INDISPO_ICONS[action].icon }}
                                </v-icon>
                                {{ ACTIONS_INDISPO_VERB[action] }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import unavailabilitiesService from '@/services/unavailabilities';

import {
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_LABEL,
    ACTIONS_INDISPO_ICONS,
} from '@/constants/unavailabilities';
import { UNAV_ACTIONS } from '@/constants/succesMessages';

import Spinner from '@/components/Spinner.vue';
import UnavSummarySection from '@/components/sections/unavs/UnavSummarySection.vue';

const fileName = 'SingleActionModal.vue';

export default {
    name: 'SingleActionModal',

    components: {
        Spinner,
        UnavSummarySection,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        unavailability: {
            type: Object,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoaderDisplayed: false,

            ACTIONS_INDISPO,
            ACTIONS_INDISPO_VERB,
            ACTIONS_INDISPO_LABEL,
            ACTIONS_INDISPO_ICONS,

            enteredComment: '',
            isValid: true,
            commentRules: [(v) => v.length <= 255 || 'La taille maximum est de 255!'],
        };
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),

        async performAction() {
            try {
                this.showSpinner();
                await unavailabilitiesService.performUnavAction(
                    this.unavailability.id,
                    this.action,
                    this.enteredComment
                );
                this.showToast({
                    title: UNAV_ACTIONS.TITLE,
                    message: UNAV_ACTIONS.MESSAGE.toParamString(ACTIONS_INDISPO_LABEL[this.action]),
                    dismiss: false,
                });
                this.hideSpinner();
                this.$emit('unavs-update');
                this.updateValue(false);
            } catch (error) {
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
                this.updateValue(false);
            }
        },

        updateValue(val) {
            this.$emit('input', !!val);
        },
        showSpinner() {
            this.isLoaderDisplayed = true;
        },
        hideSpinner() {
            this.isLoaderDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-btn {
    white-space: normal;
}

.spinner {
    position: relative;
    width: 100%;
    height: 100px;
}
</style>
