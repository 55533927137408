<template>
    <v-dialog @input="updateValue" :value="value" scrollable max-width="600">
        <v-card tile>
            <v-card-title>
                <span>{{ ACTIONS_INDISPO_LABEL[action] }}</span>

                <v-spacer />

                <v-btn icon @click="updateValue(false)" dark>
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <Spinner v-if="isLoaderDisplayed" />

            <v-container fluid>
                <div>
                    <p>
                        Vous êtes sur le point de
                        <strong>{{ ACTIONS_INDISPO_VERB[action] }}</strong>
                        .
                    </p>
                    <p>Êtes-vous sûr d'effectuer cette action ?</p>
                </div>

                <v-form v-model="isValid">
                    <v-text-field
                        outlined
                        label="Commentaire (facultatif)"
                        v-model="enteredComment"
                        :counter="255"
                        :rules="commentRules"
                        required
                    />
                </v-form>
            </v-container>

            <v-card-actions>
                <v-row no-gutters align="center" justify="end">
                    <v-col cols="2" sm="12" md="2" class="mr-2">
                        <v-btn tile depressed outlined large block @click="updateValue(false)">
                            Annuler
                        </v-btn>
                    </v-col>

                    <v-col cols="5" sm="12" md="5">
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS[action].color"
                            @click="performMassAction()"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS[action].icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB[action] }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import unavailabilitiesService from '@/services/unavailabilities';

import {
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_LABEL,
    ACTIONS_INDISPO_ICONS,
} from '@/constants/unavailabilities';
import { MASS_UNAV_ACTIONS } from '@/constants/succesMessages';

import Spinner from '@/components/Spinner.vue';

const fileName = 'MassActionModal.vue';

export default {
    name: 'MassActionModal',

    components: {
        Spinner,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        unavailabilities: {
            type: Array,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoaderDisplayed: false,

            ACTIONS_INDISPO,
            ACTIONS_INDISPO_VERB,
            ACTIONS_INDISPO_LABEL,
            ACTIONS_INDISPO_ICONS,

            enteredComment: '',
            isValid: true,
            commentRules: [(v) => v.length <= 255 || 'La taille maximum est de 255!'],
        };
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),

        async performMassAction() {
            try {
                this.showSpinner();
                await unavailabilitiesService.performMassUnavAction(
                    this.unavailabilities,
                    this.action,
                    this.enteredComment
                );
                this.showToast({
                    title: MASS_UNAV_ACTIONS.TITLE,
                    message: MASS_UNAV_ACTIONS.MESSAGE.toParamString(
                        ACTIONS_INDISPO_LABEL[this.action]
                    ),
                    dismiss: false,
                });
                this.$emit('unavs-update');
                this.hideSpinner();
                this.updateValue(false);
            } catch (error) {
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
                this.updateValue(false);
            }
        },

        updateValue(val) {
            this.$emit('input', !!val);
        },
        showSpinner() {
            this.isLoaderDisplayed = true;
        },
        hideSpinner() {
            this.isLoaderDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-btn {
    white-space: normal;
}

.spinner {
    position: relative;
    width: 100%;
    height: 100px;
}
</style>
