<template>
    <div>
        <v-row align="center">
            <v-col cols="12" sm="6" md="4">
                <v-row>
                    <v-col cols="12" lg="6">
                        <TextSelectPicker
                            dense
                            clearable
                            hide-details
                            :disabled="!beneficiaries.length"
                            v-model="selectedBeneficiaries"
                            :items="beneficiaries"
                            label="Bénéficiaire"
                            no-data-text="Aucun résultat pour ce bénéficiaire."
                            multiple
                            :maxChipsDisplayed="1"
                            deletableChips
                        />
                    </v-col>

                    <v-col cols="12" lg="6">
                        <TextSelectPicker
                            dense
                            clearable
                            hide-details
                            no-data-text="Aucun résultat pour ce motif."
                            :disabled="!reasons.length"
                            v-model="selectedReasons"
                            :items="reasons"
                            label="Motif"
                            multiple
                            :maxChipsDisplayed="1"
                            deletableChips
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <v-row>
                    <v-col cols="12" lg="6">
                        <TextDatePicker
                            clearable
                            prepend-icon="mdi-calendar"
                            dense
                            hide-details
                            :disabled="!items.length"
                            label="Début d'indisponibilité"
                            v-model="selectedStartDate"
                            :max="selectedEndDate"
                        />
                    </v-col>

                    <v-col cols="12" lg="6">
                        <TextDatePicker
                            clearable
                            prepend-icon="mdi-calendar"
                            dense
                            hide-details
                            :disabled="!items.length"
                            label="Fin d'indisponibilité"
                            v-model="selectedEndDate"
                            :min="selectedStartDate"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="4">
                <v-row justify="end" v-if="unavStatus === STATUT_INDISPO.AVALIDER">
                    <v-col cols="12" lg="6">
                        <v-btn
                            tile
                            block
                            depressed
                            :ripple="false"
                            :disabled="!selectedUnavs.length"
                            :class="ACTIONS_INDISPO_ICONS.MASS_VALIDATION.color"
                            @click="showMassActionModal($event, ACTIONS_INDISPO.MASS_VALIDATION)"
                        >
                            <v-icon left>{{ ACTIONS_INDISPO_ICONS.MASS_VALIDATION.icon }}</v-icon>
                            {{ ACTIONS_INDISPO_VERB.MASS_VALIDATION }}
                        </v-btn>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-btn
                            tile
                            block
                            depressed
                            :ripple="false"
                            :disabled="!selectedUnavs.length"
                            :class="ACTIONS_INDISPO_ICONS.MASS_REFUS.color"
                            @click="showMassActionModal($event, ACTIONS_INDISPO.MASS_REFUS)"
                        >
                            <v-icon left>{{ ACTIONS_INDISPO_ICONS.MASS_REFUS.icon }}</v-icon>
                            {{ ACTIONS_INDISPO_VERB.MASS_REFUS }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row justify="end" v-else-if="unavStatus === STATUT_INDISPO.ATTENTEANNULATION">
                    <v-col cols="12" lg="6">
                        <v-btn
                            tile
                            block
                            :ripple="false"
                            :disabled="!selectedUnavs.length"
                            :class="ACTIONS_INDISPO_ICONS.MASS_VALIDATION_ANNULATION.color"
                            @click="
                                showMassActionModal(
                                    $event,
                                    ACTIONS_INDISPO.MASS_VALIDATION_ANNULATION
                                )
                            "
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.MASS_VALIDATION_ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.MASS_VALIDATION_ANNULATION }}
                        </v-btn>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-btn
                            tile
                            block
                            depressed
                            :ripple="false"
                            :disabled="!selectedUnavs.length"
                            :class="ACTIONS_INDISPO_ICONS.MASS_REFUS_ANNULATION.color"
                            @click="
                                showMassActionModal($event, ACTIONS_INDISPO.MASS_REFUS_ANNULATION)
                            "
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.MASS_REFUS_ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.MASS_REFUS_ANNULATION }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-data-table
            class="elevation-1 mt-2"
            :loading="isTableLoading"
            loading-text="Chargement des données..."
            must-sort
            :headers="headers"
            :footer-props="footerProps"
            :options="options"
            :items="items"
            no-data-text="Aucune donnée à afficher."
            no-results-text="Aucune donnée correspond à votre recherche."
            show-select
            item-key="id"
            v-model="selectedUnavs"
        >
            <template v-slot:[`item.date_created`]="{ item }">
                {{ item.date_created | formatDateTime }}
            </template>

            <template v-slot:[`item.nom`]="{ item }">
                {{ item.nom }} {{ item.prenom }}
                <span class="modified-by" v-if="item.user_created !== item.gid_collaborateur">
                    par
                    <strong>{{ item.nom_created }} {{ item.prenom_created }}</strong>
                </span>
            </template>

            <template v-slot:[`item.date_debut`]="{ item }">
                {{ item.date_debut | formatDateTime }}
            </template>

            <template v-slot:[`item.date_fin`]="{ item }">
                {{ item.date_fin | formatDateTime }}
            </template>

            <template v-slot:[`item.lib_absence`]="{ item }">
                {{ item.indisponibilite }} - {{ item.lib_absence }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <td v-if="unavStatus === STATUT_INDISPO.AVALIDER">
                    <v-icon
                        class="pr-1"
                        color="success"
                        @click.stop="showActionModal($event, item, ACTIONS_INDISPO.VALIDATION)"
                        @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.VALIDATION)"
                    >
                        {{ ACTIONS_INDISPO_ICONS.VALIDATION.icon }}
                    </v-icon>

                    <v-icon
                        class="pr-1"
                        :color="ACTIONS_INDISPO_ICONS.REFUS.color"
                        @click.stop="showActionModal($event, item, ACTIONS_INDISPO.REFUS)"
                        @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.REFUS)"
                    >
                        {{ ACTIONS_INDISPO_ICONS.REFUS.icon }}
                    </v-icon>

                    <v-icon
                        class="pr-1"
                        color="warning"
                        @click.stop="showActionModal($event, item, ACTIONS_INDISPO.ANNULATION)"
                        @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.ANNULATION)"
                    >
                        {{ ACTIONS_INDISPO_ICONS.ANNULATION.icon }}
                    </v-icon>

                    <v-icon
                        v-if="item.commentaire_declarant || item.commentaire_valideur"
                        @click.stop="showCommentMenu($event, item)"
                        @mouseover="showCommentTooltip($event)"
                    >
                        mdi-message-text
                    </v-icon>
                </td>

                <td v-else-if="unavStatus === STATUT_INDISPO.ATTENTEANNULATION">
                    <v-icon
                        class="pr-1"
                        color="success"
                        @click.stop="
                            showActionModal($event, item, ACTIONS_INDISPO.VALIDATION_ANNULATION)
                        "
                        @mouseover="
                            showActionsTooltip($event, ACTIONS_INDISPO.VALIDATION_ANNULATION)
                        "
                    >
                        {{ ACTIONS_INDISPO_ICONS.VALIDATION_ANNULATION.icon }}
                    </v-icon>

                    <v-icon
                        class="pr-1"
                        :color="ACTIONS_INDISPO_ICONS.REFUS_ANNULATION.color"
                        @click.stop="
                            showActionModal($event, item, ACTIONS_INDISPO.REFUS_ANNULATION)
                        "
                        @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.REFUS_ANNULATION)"
                    >
                        {{ ACTIONS_INDISPO_ICONS.REFUS_ANNULATION.icon }}
                    </v-icon>

                    <v-icon
                        v-if="item.commentaire_declarant || item.com_declarant_annulation"
                        @click.stop="showCommentMenu($event, item)"
                        @mouseover="showCommentTooltip($event)"
                    >
                        mdi-message-text
                    </v-icon>
                </td>
            </template>
        </v-data-table>

        <SingleActionModal
            v-if="isActionModalDisplayed"
            v-model="isActionModalDisplayed"
            :unavailability="selectedUnav"
            :action="unavAction"
            @unavs-update="onUnavsUpdate()"
        />

        <MassActionModal
            v-if="isMassActionModalDisplayed"
            v-model="isMassActionModalDisplayed"
            :unavailabilities="selectedUnavs"
            :action="unavAction"
            @unavs-update="onUnavsUpdate()"
        />

        <v-tooltip
            bottom
            :activator="selectedElement"
            v-show="isTooltipOpened"
            v-model="isTooltipOpened"
        >
            {{ selectedMessage }}
        </v-tooltip>

        <v-menu
            v-if="isMenuOpened"
            v-model="isMenuOpened"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-y
            bottom
            max-width="450"
        >
            <CommentsSection v-if="selectedUnav" :item="selectedUnav" />
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment';
import {
    STATUT_INDISPO,
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_ICONS,
    ACTIONS_INDISPO_VERB,
} from '@/constants/unavailabilities';
import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import SingleActionModal from '@/components/unavActions/SingleActionModal.vue';
import MassActionModal from '@/components/unavActions/MassActionModal.vue';
import TextDatePicker from '@/components/pickers/TextDatePicker.vue';
import TextSelectPicker from '@/components/pickers/TextSelectPicker.vue';
import CommentsSection from '@/components/sections/table/CommentsSection.vue';

export default {
    name: 'ValidationsDataTable',

    components: {
        SingleActionModal,
        MassActionModal,
        TextDatePicker,
        TextSelectPicker,
        CommentsSection,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        isTableLoading: {
            type: Boolean,
            required: true,
        },
        unavStatus: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isMassActionModalDisplayed: false,
            isActionModalDisplayed: false,
            isTooltipOpened: false,
            isMenuOpened: false,

            selectedStartDate: '',
            selectedEndDate: '',
            selectedReasons: [],
            selectedBeneficiaries: [],
            selectedUnavs: [],

            STATUT_INDISPO,
            ACTIONS_INDISPO,
            ACTIONS_INDISPO_ICONS,
            ACTIONS_INDISPO_VERB,

            unavAction: undefined,
            selectedUnav: undefined,
            selectedMessage: undefined,
            selectedElement: null,

            footerProps: {
                itemsPerPageText: 'Éléments par page : ',
                itemsPerPageOptions: [10, 20, 30],
                pageText: '{0}-{1} sur {2}',
            },

            options: {
                itemsPerPage: 10,
                sortBy: ['date_created'],
                sortDesc: [true],
            },

            headers: [
                {
                    text: 'Date de la demande',
                    value: 'date_created',
                    width: '175px',
                },
                {
                    text: 'Bénéficiaire',
                    value: 'nom',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedBeneficiaries && this.selectedBeneficiaries.length
                            ? this.selectedBeneficiaries.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Début indisponibilité',
                    value: 'date_debut',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedStartDate
                            ? moment(value).isAfter(moment(this.selectedStartDate))
                            : true;
                    },
                },
                {
                    text: 'Fin indisponibilité',
                    value: 'date_fin',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedEndDate
                            ? moment(value).isBefore(moment(this.selectedEndDate))
                            : true;
                    },
                },
                {
                    text: 'Motif',
                    value: 'lib_absence',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedReasons && this.selectedReasons.length
                            ? this.selectedReasons.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    width: '175px',
                },
            ],
        };
    },

    computed: {
        beneficiaries() {
            return this.items
                ? this.items.map((item) => ({
                      text: `${item.nom} ${item.prenom}`,
                      value: item.nom,
                  }))
                : [];
        },
        reasons() {
            return this.items ? this.items.map((item) => item.lib_absence) : [];
        },
    },

    filters: {
        formatDateTime(date) {
            return date ? moment(date).format(DATE_TIME_FORMAT) : '';
        },
    },

    methods: {
        onUnavsUpdate() {
            this.selectedUnavs = [];
            this.$emit('unavs-update');
        },

        showActionModal(nativeEvent, unav, action) {
            this.hideTooltip();
            this.hideMenu();

            this.selectedUnav = unav;
            this.selectedMessage = undefined;
            this.selectedElement = nativeEvent.target;
            this.unavAction = action;

            this.displayActionModal();
            nativeEvent.stopPropagation();
        },
        showMassActionModal(nativeEvent, action) {
            this.hideTooltip();
            this.hideMenu();

            this.selectedMessage = undefined;
            this.selectedElement = nativeEvent.target;
            this.unavAction = action;

            this.displayMassActionModal();
            nativeEvent.stopPropagation();
        },

        showCommentTooltip(nativeEvent) {
            this.showEvent({
                nativeEvent,
                unav: undefined,
                message: 'Voir les commentaires',
                isActionModal: false,
            });
        },
        showActionsTooltip(nativeEvent, action) {
            this.showEvent({
                nativeEvent,
                unav: undefined,
                message: ACTIONS_INDISPO_VERB[action],
                isActionModal: false,
            });
        },
        showCommentMenu(nativeEvent, unav) {
            this.showEvent({
                nativeEvent,
                unav,
                message: undefined,
                isActionModal: false,
            });
        },

        showEvent({ nativeEvent, unav, message, isActionModal }) {
            const open = () => {
                this.selectedUnav = unav;
                this.selectedMessage = message;
                this.selectedElement = nativeEvent.target;

                if (message) {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.showTooltip()));
                } else if (!isActionModal) {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.showMenu()));
                }
            };

            if (this.isMenuOpened || this.isTooltipOpened) {
                this.hideTooltip();
                this.hideMenu();

                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }
            nativeEvent.stopPropagation();
        },

        showMenu() {
            this.isMenuOpened = true;
        },
        hideMenu() {
            this.isMenuOpened = false;
        },
        showTooltip() {
            this.isTooltipOpened = true;
        },
        hideTooltip() {
            this.isTooltipOpened = false;
        },

        displayActionModal() {
            this.isActionModalDisplayed = true;
        },
        displayMassActionModal() {
            this.isMassActionModalDisplayed = true;
        },

        showSpinner() {
            this.$emit('show-spinner');
        },
        hideSpinner() {
            this.$emit('hide-spinner');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-data-table-header {
    background-color: $very-light-gray;
}

.modified-by {
    color: $primary !important;
    display: block;
    font-style: italic;
    line-height: 0.6rem;
    font-size: 0.7rem;
    margin-left: -0.2rem;
}

::v-deep .v-tab {
    color: $black !important;
}
</style>
