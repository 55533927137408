<template>
    <v-dialog
        @input="onValueChange"
        :value="isDisplayed"
        scrollable
        min-width="300"
        max-width="1000"
    >
        <v-card tile>
            <v-card-title>
                <span>
                    <v-icon large dark left>mdi-history</v-icon>
                    Historique des actions
                </span>

                <v-spacer />

                <v-btn icon dark @click="onValueChange(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Date de l'action</th>
                                <th>Action</th>
                                <th>Par</th>
                                <th>Commentaire</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="historicAction in unavailabilityHistoric"
                                :key="historicAction.index"
                            >
                                <td>{{ historicAction.date_action | formatDateTime }}</td>
                                <td>
                                    {{ ACTIONS_INDISPO_LABEL[historicAction.action] }}
                                </td>
                                <td>{{ historicAction.nom }} {{ historicAction.prenom }}</td>
                                <td>{{ historicAction.commentaire }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import { ACTIONS_INDISPO_LABEL } from '@/constants/unavailabilities';

export default {
    name: 'UnavHistoricModal',

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        unavailabilityHistoric: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            isDisplayed: this.value,

            ACTIONS_INDISPO_LABEL,
        };
    },

    watch: {
        value(newVal) {
            this.isDisplayed = newVal;
        },
    },

    filters: {
        formatDateTime(date) {
            return date ? moment(date).format(DATE_TIME_FORMAT) : '';
        },
    },

    methods: {
        onValueChange(val) {
            this.$emit('input', val);
        },
    },
};
</script>
